<template>
  <div class="bigBox">
    <div class="topBox">
      <div>
        <!--        饼图-->
        <PieChat></PieChat>
      </div>
      <div>
        <!--        地图-->
        <Map></Map>
      </div>
    </div>
    <div class="bottonBox">
      <div>
        <!--        折线图-->
        <LineChart></LineChart>
      </div>
      <div>
        <!--        柱状图-->
        <Histogram></Histogram>
      </div>
    </div>
  </div>
</template>

<script>
import PieChat from '../components/dataBoard/PieChart/index'
import LineChart from '../components/dataBoard/LineChart/index'
import Map from '../components/dataBoard/Map/index'
import Histogram from '../components/dataBoard/Histogram/index'

export default {
  components: {
    PieChat, LineChart, Map,Histogram
  },
  setup() {
  }
}
</script>
<style scoped lang="less">
.bigBox {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.topBox {
  width: 100%;
  height: 53.1vh;
  display: flex;
}

.topBox > div {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 0px #E4E4E4;
  border-radius: 5px;
  overflow: hidden;
}

.topBox > div:first-child {
  width: 45.9vw;
  margin-right: 1.38vw;
}

.topBox > div:nth-child(2) {
  flex: 1;
}

.bottonBox {
  flex: 1;
  display: flex;
  margin-top: 2.4vh;
}

.bottonBox > div {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 0px #E4E4E4;
  border-radius: 5px;
  overflow: hidden;
}

.bottonBox > div:first-child {
  width: 45.9vw;
  margin-right: 1.38vw;
}

.bottonBox > div:nth-child(2) {
  flex: 1;
}


</style>
