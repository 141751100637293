<template>
<div class="bigBox">
  <tu></tu>
</div>
</template>

<script>
import tu from './tu'
export default {
  name: "index",
  components:{
    tu
  }
}
</script>

<style scoped lang="less">
.bigBox{
  height: 100%;
  width: 100%;
}
</style>
