<template>
  <div class="bigBox">
    <div ref="chartsLine" style="width: 100%; height:100%"></div>
  </div>
</template>


<script>
import * as echarts from "echarts";
import zhongguo from "@/assets/mapJson/data-city.json"
export default {
  created () {
    this.$nextTick(() => {
      this.initCharts();
    })
  },
  methods: {
    initCharts() {
      const charts = echarts.init(this.$refs["chartsLine"]);
      const option ={
        title: {
          text: '维保趋势图'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['Direct']
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月','9月','10月','11月','12月']
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true,
            },
            splitLine: {     //网格线
              "show": false
            }
          }


        ],
        series: [
          {
            name: 'Line 1',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(255,130,130)'
                },
                {
                  offset: 1,
                  color: 'rgb(255,245,245)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [300, 232, 532, 300, 432, 340, 250,300,700,400,700,500]
          },
        ]
      };
      charts.setOption(option);
    },
  },
};
</script>
<style scoped>
.bigBox{
  height: 100%;
}
</style>

