<template>
  <div class="content">
    <div ref="charts" style="width: 100%; height:100%"></div>
  </div>
</template>


<script>
import * as echarts from "echarts";
import zhongguo from "@/assets/mapJson/data-city.json"
export default {
  created () {
    this.$nextTick(() => {
      this.initCharts();
    })
  },
  methods: {
    initCharts() {
      const charts = echarts.init(this.$refs["charts"]);
      const option = {
        // 背景颜色
        backgroundColor: "#404a59",
        // 提示浮窗样式
        tooltip: {
          show: true,
          trigger: "item",
          alwaysShowContent: false,
          backgroundColor: "#0C121C",
          borderColor: "rgba(0, 0, 0, 0.16);",
          hideDelay: 100,
          triggerOn: "mousemove",
          enterable: true,
          textStyle: {
            color: "#DADADA",
            fontSize: "12",
            width: 20,
            height: 30,
            overflow: "break",
          },
          formatter(params) {
            return `地区：${params.name}</br>数值：${params.value[2]}`;
          },
          showDelay: 100
        },
        // 地图配置
        geo: {
          map: "china",
          aspectScale:0.75, //长宽比,0.75的含义为宽是高的0.75,假如高为100,宽就只有75;0.5的含义就是宽只有高的一半,假如高为100,宽就只有50
          zoom:1.2,//视觉比例大小,1.2即为原有大小的1.2倍
          roam:true, //是否开启鼠标缩放和平移漫游。默认不开启。可以不用设置,如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
          label: {
            // 通常状态下的样式
            normal: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            // 鼠标放上去的样式
            emphasis: {
              textStyle: {
                color: "#fff",
              },
            },
          },
          // 地图区域的样式设置
          itemStyle: {
            normal: {
              borderColor: "rgba(147, 235, 248, 1)",
              borderWidth: 1,
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(147, 235, 248, .2)", // 100% 处的颜色
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
              shadowColor: "rgba(128, 217, 248, 1)",
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 10,
            },
            // 鼠标放上去高亮的样式
            emphasis: {
              areaColor: "#389BB7",
              borderWidth: 0,
            },
          },
          regions: [      //对不同的区块进行着色
            {
              name: '四川省', //区块名称
              itemStyle: {
                normal: {
                  areaColor: '#8521aa'
                }
              }
            },{
              name: '河北省', //区块名称
              itemStyle: {
                normal: {
                  areaColor: '#193094'
                }
              }
            },]
        },
        series: [
          // {
          //   type: "scatter",
          //   coordinateSystem: "geo",
          //   symbol: "pin",
          //   legendHoverLink: true,
          //   symbolSize: [60, 60],
          //   // 这里渲染标志里的内容以及样式
          //   label: {
          //     show: true,
          //     formatter(value) {
          //       return value.data.value[2];
          //     },
          //     color: "#fff",
          //   },
          //   // 标志的样式
          //   itemStyle: {
          //     normal: {
          //       color: "rgba(255,0,0,.7)",
          //       shadowBlur: 2,
          //       shadowColor: "D8BC37",
          //     },
          //   },
          //   // 数据格式，其中name,value是必要的，value的前两个值是数据点的经纬度，其他的数据格式可以自定义
          //   // 至于如何展示，完全是靠上面的formatter来自己定义的
          //   data: [
          //     { name: "西藏", value: [91.23, 29.5, 2333] },
          //     { name: "黑龙江", value: [128.03, 47.01, 1007] },
          //   ],
          //   showEffectOn: "render",
          //   rippleEffect: {
          //     brushType: "stroke",
          //   },
          //   hoverAnimation: true,
          //   zlevel: 1,
          // },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            effectType: "ripple",
            showEffectOn: "render",
            rippleEffect: {
              period: 10,
              scale: 10,
              brushType: "fill",
            },

            hoverAnimation: true,
            itemStyle: {
              normal: {
                color: "rgba(255, 235, 59, .7)",
                shadowBlur: 10,
                shadowColor: "#333",
              },
            },
            zlevel: 1,
            data: [
              { name: "西藏", value: [91.23, 29.5, 2333] },
              { name: "黑龙江", value: [128.03, 47.01, 1007] },
            ],
          },
        ],

      };
      // 地图注册，第一个参数的名字必须和option.geo.map一致
      echarts.registerMap("china",zhongguo)

      charts.setOption(option);
    },
  },
};
</script>
<style scoped>
.content{
  width: 100%;
  height: 100%;
}
</style>

