<template>
<div class="tu1BigBox">
  <div class="tu1Box">
    <PieChat></PieChat>
  </div>
  <div class="tu1Bottom">
    <div class="tu1BottomList">
      <div>设备编号</div>
      <div>隐患</div>
      <div>通知</div>
      <div>安装位置</div>
      <div>时间</div>
    </div>
    <div class="tableListBox">
      <div v-for="(item,index) in formData" :key="index" :class="index%2 ==0? 'tableList':'listBgColor tableList' ">
        <div>{{ item.time }}</div>
        <div class="textStyle" :title="item.name">{{ item.name }}</div>
        <div class="textStyle">{{ item.state}}</div>
        <div class="textStyle">{{ item.title }}</div>
        <div class="textStyle">{{ item.time}}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import PieChat from './tu'
import {reactive, toRefs} from "vue";

export default {
  name: "table",
  components: {
    PieChat
  },
  setup() {
    const data = reactive({
      formData: [
        {time: '12342332 ', name: '压力不足', title: '北京市大兴区经开数字工厂', state: '处理中'},
        {time: '12342332 ', name: '已过期', title: '北京市大兴区经开数字工厂', state: '处理中'},
        {time: '12342332 ', name: '温度过高', title: '北京市大兴区经开数字工厂', state: '处理中'},
        {time: '12342332 ', name: '压力不足', title: '北京市大兴区经开数字工厂', state: '处理中'},
        {time: '12342332 ', name: '温度过高', title: '北京市大兴区经开数字工厂', state: '处理中'},
      ]
    })
    return {
      ...toRefs(data)
    }
  }
}
</script>

<style scoped lang="less">
.tu1BigBox{
  width: 100%;
  height: 100%;
}
.tu1Box {
  width: 100%;
  height: 50%
}

.tu1Bottom {
  width: 38.6vw;
  margin: 0 auto;

.tu1BottomList {
  display: flex;
  justify-content: space-evenly;
  height: 4.6vh;
  line-height: 4.6vh;
  background: #EDFFF6;
  opacity: 1;
  border: 1px solid #02CA9DFF;
  border-radius: 8px 8px 8px 8px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 1vw;

div:first-child{
//margin-left: 2.2vw;
  width: 4.72vw;
}
div:nth-child(2){
//margin-left: 2.63vw;
  width: 3.88vw;
}
div:nth-child(3){
//margin-left: 2.63vw;
  width: 3vw;
}
div:nth-child(4){
//margin-left: 2.63vw;
  width: 12vw;
}
div:nth-child(5){
//margin-left: 2.63vw;
  width: 4vw;
}

}

.tu1BottomList > div {
  border-image: linear-gradient(180deg, rgba(2, 202, 157, 1), rgba(2, 202, 157, 0)) 1 1;
}

.tableListBox{
//margin-top: 1vw;
}
.tableList {
  display: flex;
  justify-content: space-evenly;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 0.97vw;
div{
  height: 3.65vh;
  line-height: 3.65vh;
}
div:first-child{
//margin-left: 2.2vw;
  width: 4.72vw;
}
div:nth-child(2){
//margin-left: 2.63vw;
  width: 3.88vw;
}
div:nth-child(3){
//margin-left: 2.63vw;
  width: 3vw;
}
div:nth-child(4){
//margin-left: 2.63vw;
  width: 12vw;
}
div:nth-child(5){
//margin-left: 2.63vw;
  width: 4vw;
}




}
.listBgColor{
  background: #EDFFF6;
}
}

.textStyle{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
