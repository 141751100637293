// 保存缓存
export function login(user, user_token) {
    sessionStorage.setItem('JN_user', JSON.stringify(user))
    sessionStorage.setItem('JN_user_token', JSON.stringify(user_token))
    // localStorage.setItem('JN_user', JSON.stringify(user))
    // localStorage.setItem('JN_user_token', JSON.stringify(user_token))
    return true;
}
// 获取用户信息
export function info() {
    let user = JSON.parse(sessionStorage.getItem('JN_user'))
    // let user = JSON.parse(localStorage.getItem('JN_user'))
    return user
}
// 获取用户token
export function getToken() {
    return sessionStorage.getItem('JN_user_token')
    // return localStorage.getItem('JN_user_token')
}

// 日期截取
export function dateFormat(value) {
    if (value) {
        return value.substring(0, 10);
    }
}

export function filterParams(obj) {
    let _newPar = {};
    for (let key in obj) {
        //如果对象属性的值不为空，就保存该属性（这里我做了限制，如果属性的值为0，保存该属性。如果属性的值全部是空格，属于为空。）
        // if (obj[key].toString().replace(/(^\s*)|(\s*$)/g,'') !== '') {
            if (( obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
            //记录属性
            _newPar[key] = obj[key];
        }
    }
    //返回对象
    return _newPar;
}



export default {
    login,
    info,
    dateFormat,
    filterParams,
    getToken

}
