<template>
  <div class="content">
    <div ref="chartsPieChart" style="width: 100%; height:100%;"></div>
  </div>
</template>


<script>
import * as echarts from "echarts";
export default {
  created () {
    this.$nextTick(() => {
      this.initCharts();
    })
  },
  methods: {
    initCharts() {
      const charts = echarts.init(this.$refs["chartsPieChart"]);
      const option ={
        title: {
          text: '设备概览',
              subtext: '设备总数：1562个',
              left: 'left'
        },
        // tooltip: {
        //   trigger: 'item'
        // },
        color: [
          '#89DCFFFF',
          '#89D0FFFF',
          '#89BBFFFF',
          '#89D0FFFF',
          '#89DCFFFF',
          '#AEE7FFFF',
          '#89D0FFFF',

        ],
        series: [
          {
            name: '灭火器',
            type: 'pie',
            radius: '80%',
            data: [
              { value: 1048, name: '北京市' },
              { value: 735, name: '上海市' },
              { value: 580, name: '重庆' },
              { value: 484, name: '云南' },
              { value: 300, name: '湖北' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              label: {
                show: true,
                // 高亮时标签的文字。
                formatter:'{b}:\n\n灭火器：{c}个\n\n占比：{d}%'
              }
            },
            label: {
              show: false
            },
          },

        ],

      }




      charts.setOption(option);

// 默认展示第一条数据的提示框
      charts.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: 0
      });

// 默认高亮展示第一条数据
      charts.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: 0
      });

      charts.on('mouseover', (v) => {
        if (v.dataIndex != 0) {
          // 高亮显示悬停的那块
          charts.dispatchAction({
            type: 'hideTip',
            seriesIndex: 0,
            dataIndex: 0
          });
          // 当检测到鼠标悬停事件，取消所有选中高亮
          charts.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: 0
          });
        }
      });

// 检测鼠标移出后显示之前默认高亮的那块
      charts.on('mouseout', (v) => {
        charts.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: 0
        });
        charts.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: 0
        });
      });
    },
  },
};
</script>
<style scoped lang="less">
.content{
  height: 100%;
}
</style>

