<template>
  <div class="bigBox">
    <div ref="chartsHistogram" style="width: 100%; height:100%"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import zhongguo from "@/assets/mapJson/data-city.json"
export default {
  created () {
    this.$nextTick(() => {
      this.initCharts();
    })
  },
  methods: {
    initCharts() {
      const charts = echarts.init(this.$refs["chartsHistogram"]);
      const option ={
        title: {
          text: '完好率近7天'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['8月1号', '8月2号', '8月3号', '8月4号', '8月5号', '8月6号', '8月7号']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [
              {
                value: 200,
                itemStyle: {
                  color: '#B9E3FFFF'
                }
              },
              {
                value: 200,
                itemStyle: {
                  color: '#BCE8DAFF'
                }
              },
              {
                value: 200,
                itemStyle: {
                  color: '#E7F3CFFF'
                }
              },
              {
                value: 680,
                itemStyle: {
                  color: '#F2D3F7FF'
                }
              },
              {
                value: 540,
                itemStyle: {
                  color: '#CFF6DAFF'
                }
              },
              {
                value: 790,
                itemStyle: {
                  color: '#F7D5D6FF'
                }
              },
              {
                value: 150,
                itemStyle: {
                  color: '#EDF4D2FF'
                }
              },
            ],
            type: 'bar'
          }
        ]
      };
      // 地图注册，第一个参数的名字必须和option.geo.map一致
      echarts.registerMap("china",zhongguo)

      charts.setOption(option);
    },
  },
};
</script>
<style scoped>
.bigBox{
  height: 100%;
}
</style>

