import { createStore } from 'vuex'
import  createPersistedstate from"vuex-persistedstate"
export default createStore({
  plugins:[createPersistedstate({
    storage:window.localStorage
  })],
  state: {
    routrUrl:{}
  },
  getters: {
  },
  mutations: {
    routrUrlFUN(state, payload) {
      state.routrUrl = payload
    }
  },
  actions: {
    routrUrl(context, payload) {
      context.commit('routrUrlFUN', payload)
    }
  },
  modules: {
  }
})
