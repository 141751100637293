import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/dataBoard.vue'
const routes = [
  //  登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  //  首页
  {
    path: "/",
    name: "Home",
    component: () => import("../views/main"),
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      //  数据报表
      {
        path: '/dataBoard',
        name: 'dataBoard',
        component: () => import("../views/dataBoard"),
      },
      //  设备管理
      {
        path: '/deviceManagement',
        name: 'deviceManagement',
        component: () => import("../views/deviceManagement"),
      },
      //  报告报表
      {
        path: '/reportReport',
        name: 'reportReport',
        component: () => import("../views/reportReport"),
      },
      //  报告报表 查看页面
      {
        path: '/reportReport_seePage',
        name: 'reportReport_seePage',
        component: () => import("../components/reportReport/seePage"),
      },
      //  报告报表 报告报表
      {
        path: '/reportReport_viewReport',
        name: 'reportReport_viewReport',
        component: () => import("../components/reportReport/viewReport"),
      },
      //  报告报表 报告详情
      {
        path: '/reportReport_viewDetails',
        name: 'reportReport_viewDetails',
        component: () => import("../components/reportReport/viewDetails"),
      },
      //  巡检巡查
      {
        path: '/patrolInspection',
        name: 'patrolInspection',
        component: () => import("../views/patrolInspection"),
      },
      //  巡检巡查
      {
        path: '/patrolInspection_viewDetails',
        name: 'patrolInspection_viewDetails',
        component: () => import("../components/patrolInspection/patrolInspection_viewDetails"),
      },
      //  项目管理
      {
        path: '/projectManagement',
        name: 'projectManagement',
        component: () => import("../views/projectManagement"),
      },
      //  账户管理
      {
        path: '/accountManagement',
        name: 'accountManagement',
        component: () => import("../views/accountManagement"),
      },
      //  退出账号
      {
        path: '/exitAccount',
        name: 'exitAccount',
        component: () => import("../views/exitAccount"),
      },



    ]

  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async(to,from,next)=>{
 let info =  $loginUserToken.info()
  if(info == null){
    if (to.path == '/login') {
      next();
    } else if(to.path == '/register') {
      next();
    }else{
      next({path: '/login'})
    }
    return
  }else {
    next()
  }
})



export default router
