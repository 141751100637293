<template>
  <div class="bigBox">
    <div class="bigBoxTop">
    <div class="title">设备分布图</div>
      <div class="SelectorBox"><RegionSelector></RegionSelector></div>
    </div>
    <div class="bigBoxBottom">
      <tu></tu>
    </div>
  </div>
</template>

<script>
import tu from './tu'
import RegionSelector from "@/components/dataBoard/Map/RegionSelector";
export default {
  name: "index",
  components: {tu,RegionSelector}
}
</script>

<style scoped>
.bigBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bigBoxTop {
  width: 100%;
  height: 12vh;
}

.bigBoxBottom {
  width: 100%;
  flex: 1;
}

.title{
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 1.5vw;
  margin-left: 1.1vw;
  margin-top: 1.2vh;
  height: 4vh;
}
.SelectorBox{
  margin-top: 1.9vh;
  margin-left: 1.1vw;
}
</style>
