<template>
  <div>
    <router-view/>
  </div>
</template>
<style>
* {
  margin: 0;
  padding: 0;
}

/*弹框*/
.PopupBigBox {
  width: 100vw;
  height: 100vh;
  float: right;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.4);
  z-index: 1000;
}

.PopupBigBoxContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*字体颜色*/
.textColor1 {
  color: #02CB9DFF !important;
  cursor: pointer;
}
/*鼠标放上变小手*/
cursorStyle{
  cursor: pointer;

}
</style>
