<template>
<div class="bigBoxS">
  <div class="bigBoxItem">
    <div>选择省份</div>
    <div class="icon"></div>
  </div>
  <div class="bigBoxItem">
    <div>选择省份</div>
    <div class="icon"></div>
  </div>
  <div class="bigBoxItem">
    <div>选择省份</div>
    <div class="icon"></div>
  </div>

</div>
</template>

<script>
export default {
  name: "RegionSelector"
}
</script>

<style scoped lang="less">
.bigBoxS{
  display: flex;
  flex-direction: row;
}
.bigBoxItem{
  display: flex;
  height: 4.1vh;
  width: 7.7vw;
  line-height: 4.1vh;
  text-align: center;
  background: #02CB9D;
  opacity: 1;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  font-size: 1.1vw;
  margin-right: 0.7vw;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  .icon{
    width: 1.66vw;
    height: 1.66vw;
    //border: 1px red solid;
    margin-left: 2px;

  }
}
</style>
