<template>
<div class="bigbox">
  <tu></tu>
</div>
</template>

<script>
import tu from './tu'
export default {
  name: "index",
  components:{
    tu
  }
}
</script>

<style scoped>
.bigbox{
  width: 100%;
  height: 100%;
}
</style>
